import React, { lazy } from "react";

import AuthProtect from "components/Auth/AuthProtect";
import { LayoutDefault } from "layouts/Default";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";

const ClientsList = Loadable(lazy(() => import("./pages/list")));
const ClientsDetails = Loadable(lazy(() => import("./pages/Details")));

const clientsRoutes = {
  path: PATH_APP.clientes.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.clientes.root,
      element: <ClientsList />,
    },
    {
      path: PATH_APP.clientes.info,
      element: <ClientsDetails />,
    },
  ],
};

export default clientsRoutes;
