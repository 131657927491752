import React, { lazy } from "react";

import { Loadable } from "utils/routes";
import { PATH_APP } from "routes/paths";
import { LayoutDefault } from "layouts/Default";
import AuthProtect from "components/Auth/AuthProtect";

const ProposalsList = Loadable(lazy(() => import("./pages/List")));
const ProposalsDetails = Loadable(lazy(() => import("./pages/Details")));

const proposalRoutes = {
  path: PATH_APP.propostas.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.propostas.root,
      element: <ProposalsList />,
    },
    {
      path: PATH_APP.propostas.details,
      element: <ProposalsDetails />,
    },
  ],
};

export default proposalRoutes;
