import { Dialog, type DialogProps } from "@verde/compose-ui";
import {
  AlertCircleIcon,
  CheckIcon,
  FlagIcon,
  HelloSymbol,
  HomeIcon,
  LightIcon,
  PlayIcon,
  SquareStackIcon,
} from "@verde/icons";
import { Alert } from "@verde/ui-core";
import { createElement, lazy, memo } from "react";

import { useNavigate } from "react-router-dom";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";
import { ProgressBar } from ".";
import { useMissionsContext } from "../context";

const ProposalMissionCard = Loadable(
  lazy(() => import("../components/proposal-mission-card")),
);

interface ProposalDialogProps extends Pick<DialogProps, "open"> {}

function ProposalDialog({ open }: ProposalDialogProps) {
  const { missionDialog, closeMissionDialog, startOrAdvanceMission } =
    useMissionsContext<
      "IDLE" | "SUCCESS" | "WARNING" | "INCOMPLETE" | "TIMEOUT"
    >();
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      size="sm"
      header={{
        title: (
          <div className="flex items-center gap-2 text-brand-green-dark">
            {createElement(
              {
                IDLE: FlagIcon,
                SUCCESS: HelloSymbol,
                WARNING: AlertCircleIcon,
                INCOMPLETE: AlertCircleIcon,
                TIMEOUT: AlertCircleIcon,
              }[missionDialog.status],
              {
                className: "w-6 h-6",
              },
            )}

            <h2 className="text-2xl font-semibold">
              {
                {
                  IDLE: "2ª missão: Enviar proposta",
                  SUCCESS: "Parabéns, missão completa!",
                  WARNING: "Atenção! Não será possível enviar",
                  INCOMPLETE: "Não é possível enviar mais propostas",
                  TIMEOUT: "Missão incompleta",
                }[missionDialog.status]
              }
            </h2>
          </div>
        ),
        kind: (
          {
            IDLE: "success",
            SUCCESS: "success",
            WARNING: "error",
            INCOMPLETE: "error",
            TIMEOUT: "error",
          } as const
        )[missionDialog.status],
      }}
      footer={{
        ...{
          IDLE: {
            secondary: {
              children: "Começar",
              onClick: closeMissionDialog,
            },
          },
          SUCCESS: {
            secondary: {
              icon: <PlayIcon />,
              children: "3ª missão: Aprovação completa",
              onClick: () => startOrAdvanceMission(true),
            },
          },
          WARNING: {
            secondary: {
              children: "Fechar",
              onClick: closeMissionDialog,
            },
          },
          INCOMPLETE: {
            secondary: {
              children: "Salvar como rascunho",
              icon: <SquareStackIcon />,
              onClick: () => {
                navigate(PATH_APP.propostas.root);
                closeMissionDialog();
              },
            },
          },
          TIMEOUT: {
            secondary: {
              children: "Voltar a tela inicial",
              onClick: () => navigate("/"),
              icon: <HomeIcon />,
            },
          },
        }[missionDialog.status],
        children: (
          <>
            {missionDialog.status === "IDLE" && (
              <Alert kind="warning">
                Caso o prazo ou tentativas dessa missão se esgotar, não será
                permitido criar novas propostas. Se acontecer entraremos em
                contato.
              </Alert>
            )}

            {missionDialog.status === "SUCCESS" && (
              <Alert
                classNames={{
                  base: "border border-brand-yellow bg-neutral-light-pure",
                }}
              >
                <div className="flex items-center gap-4">
                  <LightIcon className="h-6 w-6" />
                  <p>
                    <b>Lembre-se: quanto mais melhor!</b> Iremos avaliar uma por
                    vez, por isso sempre busque enviar propostas de seus
                    produtores mais qualificados.
                  </p>
                </div>
              </Alert>
            )}

            {missionDialog.status === "TIMEOUT" && (
              <Alert kind="warning">
                Não se preocupe. Entraremos em contato com você o mais breve
                possível
              </Alert>
            )}
          </>
        ),
      }}
      classNames={{
        body: "flex flex-col gap-6",
      }}
    >
      {missionDialog.status !== "IDLE" && (
        <div className="flex items-center gap-2">
          {missionDialog.status === "SUCCESS" && (
            <CheckIcon className="h-5 w-5 text-brand-green-dark" />
          )}

          <p className="text-xl font-bold text-brand-green-dark">
            {
              {
                SUCCESS: "Proposta enviada para análise",
                WARNING:
                  "O número de tentativas para envio de proposta chegou ao limite",
                INCOMPLETE:
                  "O número de tentativas para envio de proposta chegou ao limite",
                TIMEOUT: "O prazo para concluir sua missão acabou.",
              }[missionDialog.status]
            }
          </p>
        </div>
      )}

      <ProposalMissionCard>
        {missionDialog.status === "TIMEOUT" && <ProgressBar />}
      </ProposalMissionCard>

      {missionDialog.status === "IDLE" && (
        <p className="text-xl font-bold text-neutral-dark-3">
          Comece selecionando um produtor bem qualificado.
        </p>
      )}

      {missionDialog.status === "TIMEOUT" && (
        <div className="grid gap-2">
          <p className="text-xl font-bold text-neutral-dark-3">
            O prazo para concluir sua missão acabou.
          </p>
          <p className="text-lg font-semibold text-neutral-dark-3">
            Infelizmente não será possível continuar cadastrando novas propostas
          </p>
        </div>
      )}
    </Dialog>
  );
}

export default memo(ProposalDialog);
