import { CardHeader } from "@verde/compose-ui";
import { FlagIcon, XIcon } from "@verde/icons";
import { motion } from "framer-motion";
import { Fragment, lazy, memo } from "react";
import { tv } from "tailwind-variants";

import { Alert } from "@verde/ui-core";
import { useNavigate } from "react-router-dom";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";
import { ProgressBar, ProposalContractMissionCard } from ".";
import { useMissionsContext } from "../context";

const ProducerMissionCard = Loadable(
  lazy(() => import("../components/producer-mission-card")),
);

const ProposalMissionCard = Loadable(
  lazy(() => import("../components/proposal-mission-card")),
);

const ProposalApprovedMissionCard = Loadable(
  lazy(() => import("../components/proposal-approved-mission-card")),
);

interface StatusMonitorContentProps {
  onClose: () => void;
}

const styles = tv({
  slots: {
    overlay: "fixed inset-0 bg-neutral-dark-pure opacity-60 z-10",
    base: "absolute top-0 left-0 right-0 bg-neutral-light-2 rounded p-2 z-10 max-h-[90vh] overflow-y-auto scrollbar-none",
    content: "rounded bg-neutral-light-pure h-full p-4 relative grid gap-4",
  },
});

const animate = {
  content: {
    hidden: {
      opacity: 0,
      transition: {
        height: {
          duration: 0.4,
        },
        opacity: {
          duration: 0.25,
        },
      },
    },
    show: {
      opacity: 1,
      transition: {
        height: {
          duration: 0.4,
        },
        opacity: {
          duration: 0.25,
          delay: 0.15,
        },
      },
    },
  },
};

function StatusMonitorContent({ onClose }: StatusMonitorContentProps) {
  const navigate = useNavigate();
  const { activeMission, missionDialog, closeMissionDialog } =
    useMissionsContext<"SUCCESS" | "INCOMPLETE" | "TIMEOUT" | "IDLE">();

  const timeoutMessages = [
    "Infelizmente não será possível continuar cadastrando novos produtores.",
    "Infelizmente não será possível continuar criando propostas.",
  ];

  return (
    <Fragment key="status-monitor-content">
      <div className={styles().overlay()} onClick={onClose} />

      <motion.div
        className={styles().base()}
        key="status-monitor-content-animated"
        variants={animate.content}
        initial="hidden"
        exit="hidden"
        animate="show"
      >
        <div className={styles().content()}>
          <CardHeader
            action={{
              icon: <XIcon />,
              onClick: onClose,
            }}
            title={
              <div className="flex items-center gap-2">
                <FlagIcon className="h-8 w-8 text-brand-green-dark" />

                <div className="grid text-brand-green-dark">
                  <p className="text-lg font-bold">
                    Rumo ao primeiro desembolso!
                  </p>
                  <p className="text-base font-normal">Mais detalhes</p>
                </div>
              </div>
            }
          />

          <div className="grid gap-4">
            {activeMission.step >= 1 && (
              <ProducerMissionCard
                size="large"
                onClick={() =>
                  navigate(PATH_APP.workflowClient.search, {
                    state: {
                      trigger_mission_dialog: true,
                    },
                  })
                }
                {...{
                  tag: {
                    label: {
                      SUCCESS: "Completa",
                      INCOMPLETE: "Incompleto",
                      TIMEOUT: "Prazo encerrado",
                      IDLE: "Em andamento",
                    }[missionDialog.missions?.[1] || "IDLE"],
                    kind:
                      missionDialog.missions?.[1] === "SUCCESS"
                        ? "success"
                        : missionDialog.missions?.[1] === "IDLE"
                          ? "highlight"
                          : "error",
                  },
                }}
                defaultExpanded={activeMission.step === 1}
              />
            )}
            {activeMission.step >= 2 && (
              <ProposalMissionCard
                size="large"
                onClick={() =>
                  navigate(PATH_APP.workflowProposal.root, {
                    state: {
                      trigger_mission_dialog: true,
                    },
                  })
                }
                {...{
                  tag: {
                    label: {
                      SUCCESS: "Completa",
                      INCOMPLETE: "Incompleto",
                      TIMEOUT: "Prazo encerrado",
                      IDLE: "Em andamento",
                    }[missionDialog.missions?.[2] || "IDLE"],
                    kind:
                      missionDialog.missions?.[2] === "SUCCESS"
                        ? "success"
                        : missionDialog.missions?.[2] === "IDLE"
                          ? "highlight"
                          : "error",
                  },
                }}
                defaultExpanded={activeMission.step === 2}
              />
            )}
            {activeMission.step >= 3 && (
              <ProposalApprovedMissionCard
                size="large"
                onClick={() => {
                  navigate(PATH_APP.propostas.root, {
                    state: { by_status: "PROPOSAL", by_step: "confirmation" },
                  });
                  closeMissionDialog();
                }}
                {...{
                  tag: {
                    label: {
                      SUCCESS: "Completa",
                      INCOMPLETE: "Incompleto",
                      TIMEOUT: "Prazo encerrado",
                      IDLE: "Em andamento",
                    }[missionDialog.missions?.[3] || "IDLE"],
                    kind:
                      missionDialog.missions?.[3] === "SUCCESS"
                        ? "success"
                        : missionDialog.missions?.[3] === "IDLE"
                          ? "highlight"
                          : "error",
                  },
                }}
                defaultExpanded={activeMission.step === 3}
              />
            )}
            {activeMission.step >= 4 && (
              <ProposalContractMissionCard
                size="large"
                onClick={() =>
                  navigate(PATH_APP.propostas.root, {
                    state: {
                      by_status: "CONTRACT",
                      by_step: "formalization",
                    },
                  })
                }
                {...{
                  tag: {
                    label: {
                      SUCCESS: "Completa",
                      INCOMPLETE: "Incompleto",
                      TIMEOUT: "Prazo encerrado",
                      IDLE: "Em andamento",
                    }[missionDialog.missions?.[4] || "IDLE"],
                    kind:
                      missionDialog.missions?.[4] === "SUCCESS"
                        ? "success"
                        : missionDialog.missions?.[4] === "IDLE"
                          ? "highlight"
                          : "error",
                  },
                }}
                defaultExpanded={activeMission.step === 4}
              />
            )}
          </div>

          {!["SUCCESS", "INCOMPLETE", "TIMEOUT"].includes(
            activeMission.status,
          ) && (
            <ProgressBar
              classNames={{
                base: "mt-2",
              }}
            />
          )}

          {activeMission.step <= 2 && activeMission.status === "TIMEOUT" && (
            <div className="grid gap-2 rounded border border-feedback-error p-4">
              <p className="text-lg font-semibold text-neutral-dark-3">
                Prazo encerrado
              </p>

              <p className="text-base font-normal text-neutral-dark-3">
                {timeoutMessages[activeMission.step - 1]}
              </p>

              <Alert kind="warning">
                Entraremos em contato com você o mais breve possível.
              </Alert>
            </div>
          )}
        </div>
      </motion.div>
    </Fragment>
  );
}

export default memo(StatusMonitorContent);
