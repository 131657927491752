import { Dialog } from "@verde/compose-ui";
import type { Advisor } from "@verde/entities";
import {
  ChevronLeftIcon,
  FlagIcon,
  HelloSymbol,
  PlayIcon,
  XIcon,
} from "@verde/icons";
import { useAuthContext } from "@verde/modules";
import { Button, IconButton } from "@verde/ui-core";
import { motion } from "framer-motion";
import {
  type ReactNode,
  createElement,
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useMissionsContext } from "modules/missions/context";
import { Loadable } from "utils/routes";
import { Start } from "./start";
import { Tips } from "./tips";

const Missions = Loadable(lazy(() => import("./missions")));

export interface GuideDialogProps {
  open?: boolean;
  onClose?(): void;
  currentStep?: number;
  preventFlow?: boolean;
}

function GuideDialog({
  open: isOpen = false,
  preventFlow = false,
  onClose,
  currentStep: step,
}: GuideDialogProps) {
  const { user } = useAuthContext<Advisor>();
  const {
    shouldDisplayGuide,
    startOrAdvanceMission,
    isStartingOrAdvancingMission,
  } = useMissionsContext();

  const [open, setOpen] = useState(isOpen || shouldDisplayGuide);
  const [currentStep, setCurrentStep] = useState(step || 0);

  useEffect(
    () => setOpen(isOpen || shouldDisplayGuide),
    [isOpen, shouldDisplayGuide],
  );

  const disableAnimation = useMemo(() => step !== undefined, [step]);

  const STEPS = useMemo(() => {
    return [
      {
        icon: HelloSymbol,
        title: `Olá ${
          user?.name?.split(" ")?.[0]
        }, bem-vindo a Plataforma Verde`,
        children: <Start />,
        footer: {
          children: "Ver passo a passo",
        },
      },
      {
        icon: FlagIcon,
        title: "Rumo ao primeiro desembolso!",
        children: <Missions />,
        footer: {
          children: "Continuar",
        },
      },
      {
        title: "E por último...",
        children: <Tips />,
        footer: {
          icon: <PlayIcon />,
          children: "Começar 1ª missão",
        },
      },
    ];
  }, [user]);

  const STEP = useMemo(() => {
    return STEPS?.[currentStep];
  }, [STEPS, currentStep]);

  const isLastItem = useMemo(() => {
    return STEPS.length - 1 === currentStep;
  }, [currentStep, STEPS]);

  const handleNext = useCallback(async () => {
    if (isLastItem) {
      await startOrAdvanceMission();
      setOpen(false);
      return;
    }

    setCurrentStep((prev) => prev + 1);
  }, [isLastItem, startOrAdvanceMission]);

  const handlePrev = useCallback(() => {
    setCurrentStep((prev) => prev - 1);
  }, []);

  if (open === false) return null;

  return (
    <Dialog open={open}>
      <div className="mt-6 grid gap-2">
        <motion.div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            {STEP.icon ? (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                transition={{
                  delay: disableAnimation ? 0 : 0.5,
                }}
              >
                {createElement(STEP.icon, {
                  className: "w-6 h-6 text-brand-green-dark",
                })}
              </motion.div>
            ) : (
              <IconButton aria-label="Voltar" onClick={handlePrev}>
                <ChevronLeftIcon />
              </IconButton>
            )}
            <motion.h2
              className="text-2xl font-semibold text-brand-green-dark"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                delay: disableAnimation ? 0 : 1.5,
              }}
            >
              {STEP.title}
            </motion.h2>
          </div>
          {onClose && (
            <IconButton onClick={() => onClose?.()}>
              <XIcon />
            </IconButton>
          )}
        </motion.div>

        {currentStep > 0 && (
          <div
            className={`w-[56px] h-1 rounded-full ${
              currentStep === 1 ? "bg-brand-green-dark" : "bg-brand-yellow"
            }`}
          />
        )}
      </div>

      <motion.div
        className="mt-6 grid gap-8"
        initial={{
          opacity: 0,
          height: 0,
          display: "none",
          overflow: "hidden",
        }}
        animate={{
          opacity: 1,
          height: "auto",
          display: "grid",
        }}
        transition={{
          delay: disableAnimation ? 0 : 2.5,
          ease: "linear",
        }}
      >
        <motion.div
          key={`guide-item-${currentStep}`}
          initial={{
            opacity: disableAnimation ? 1 : 0,
          }}
          animate={{
            opacity: 1,
          }}
        >
          {STEP.children}
        </motion.div>

        {!preventFlow && (
          <Button
            size="lg"
            kind={isLastItem ? "primary" : "secondary"}
            onClick={handleNext}
            loading={isStartingOrAdvancingMission}
            icon={STEP.footer.icon}
          >
            {STEP.footer.children}
          </Button>
        )}
      </motion.div>
    </Dialog>
  );
}

export default memo(GuideDialog);
