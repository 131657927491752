import { Box, Spinner } from "@verde/ui";
import { ReactNode, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PATH_PAGE, PATH_APP } from "routes/paths";
import { useAuthContext, Notification } from "@verde/modules";
import { IAdvisor } from "@verde/entities";

type AuthProtectProps = {
  children: ReactNode;
};

export default function AuthProtect({ children }: AuthProtectProps) {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, user } = useAuthContext<IAdvisor>();

  useEffect(() => {
    if (
      (process.env.REACT_APP_ENVIRONMENT === "production" ||
        process.env.REACT_APP_ENVIRONMENT === "beta") &&
      !isLoading &&
      isAuthenticated &&
      user &&
      user.scheduled === false
    ) {
      navigate(PATH_APP.onboarding.appointment, {
        replace: true,
      });
    }
  }, [isLoading, isAuthenticated, user, navigate]);

  if (isLoading) {
    return (
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="lg" />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={PATH_PAGE.auth.login} />;
  }

  return <Notification.Provider>{children}</Notification.Provider>;
}
