import { Dialog, type DialogProps } from "@verde/compose-ui";
import { ExclamationTriangleIcon, FlagIcon, PlusIcon } from "@verde/icons";
import { Alert, Text } from "@verde/ui-core";
import { createElement, lazy, memo } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";
import { useMissionsContext } from "../context";

const ProposalApprovedMissionCard = Loadable(
  lazy(() => import("../components/proposal-approved-mission-card")),
);

interface ProposalApprovedDialogProps extends Pick<DialogProps, "open"> {}

function ProposalApprovedDialog({ open }: ProposalApprovedDialogProps) {
  const navigate = useNavigate();
  const { missionDialog, closeMissionDialog } = useMissionsContext<"IDLE">();

  return (
    <Dialog
      open={open}
      size="sm"
      header={{
        title: (
          <div className="flex items-center gap-2 text-brand-green-dark">
            {createElement(
              {
                IDLE: FlagIcon,
              }[missionDialog.status],
              {
                className: "w-6 h-6",
              },
            )}

            <h2 className="text-2xl font-semibold">
              {
                {
                  IDLE: "3ª missão: Aprovação completa",
                }[missionDialog.status]
              }
            </h2>
          </div>
        ),
        kind: "success",
      }}
      footer={{
        primary: {
          kind: "secondary",
          children: "Criar proposta",
          onClick: () => {
            navigate(PATH_APP.workflowProposal.root);
            closeMissionDialog();
          },
          icon: <PlusIcon />,
        },
        secondary: {
          children: "Fechar",
          onClick: closeMissionDialog,
        },
        children: (
          <>
            <Alert
              kind="warning"
              classNames={{
                base: "items-center font-semibold",
                icon: "w-6 h-6",
              }}
              icon={<ExclamationTriangleIcon />}
              bordered
            >
              A partir de agora você terá 60 dias corridos. Aumente suas chances
              enviando até cinco propostas para análise crédito.
            </Alert>
          </>
        ),
      }}
      classNames={{
        body: "flex flex-col gap-6",
      }}
    >
      <ProposalApprovedMissionCard />

      <div className="space-y-2">
        <Text className="font-bold">O que é uma aprovação completa?</Text>

        <Text>
          Aprovação completa é quando uma proposta entra com sucesso na etapa de
          formalização de contrato.
        </Text>
      </div>
    </Dialog>
  );
}

export default memo(ProposalApprovedDialog);
