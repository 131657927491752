import { HeaderNavigation } from "@verde/compose-ui";
import {
  type IAdvisor,
  VERDE_PRIVACY_POLICY,
  VERDE_TERMS_OF_USAGE,
  VERDE_WHATSAPP_LINK,
} from "@verde/entities";
import {
  BellIcon,
  BuildingIcon,
  CalculatorIcon,
  CardBankIcon,
  DashboardIcon,
  DocumentClipboardIcon,
  ExternalLinkIcon,
  FarmProducerIcon,
  KeyIcon,
  LogoutIcon,
  UserIcon,
  WhatsappIcon,
} from "@verde/icons";
import { Notification, useAuthContext } from "@verde/modules";
import { Button, IconButton, Menu, Ping } from "@verde/ui-core";
import { FloatingProvider } from "@verde/utils";
import FaqDialog, {
  type FaqDialogProps,
} from "components/faq-dialog/faq-dialog";
import { Missions } from "modules/missions";
import { useMissionsContext } from "modules/missions/context";
import { GuideDialog } from "modules/onboarding/components";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import * as S from "./styles";

interface LayoutDefaultContextValue {
  handleOpenFaq: (home?: FaqDialogProps["home"]) => void;
}

const LayoutDefaultContext = createContext<
  LayoutDefaultContextValue | undefined
>(undefined);

export function useLayoutDefault() {
  const context = useContext(LayoutDefaultContext);
  if (!context) {
    throw new Error(
      "useLayoutDefault must be used within LayoutDefaultProvider",
    );
  }

  return context;
}

export function LayoutDefault() {
  const navigate = useNavigate();
  const { user, logout } = useAuthContext<IAdvisor>();
  const { isRestricted } = useMissionsContext();
  const {
    unreadNotificationsCount,
    toggleNotificationsDrawer,
    notificationPopupAnchorPoint,
  } = Notification.useNotifications();

  const [faq, setFaq] = useState<{
    open: boolean;
    home?: FaqDialogProps["home"];
  }>({
    open: false,
    home: undefined,
  });

  const handleOpenFaq = useCallback((home?: FaqDialogProps["home"]) => {
    setFaq({
      open: true,
      home,
    });
  }, []);

  const handleCloseFaq = useCallback(() => {
    setFaq({
      open: false,
      home: undefined,
    });
  }, []);

  const contextValue = useMemo(() => {
    return {
      handleOpenFaq,
    };
  }, [handleOpenFaq]);

  return (
    <LayoutDefaultContext.Provider value={contextValue}>
      <FloatingProvider
        defaultElements={[
          {
            id: "chat",
            children: (
              <Menu
                align="start"
                items={[
                  {
                    text: "Iniciar chat",
                    icon: <WhatsappIcon />,
                    onClick: () => {
                      window.open("https://wa.me", "_blank");
                    },
                  },
                  {
                    text: "Dúvidas frequentes",
                    icon: <ExternalLinkIcon />,
                    onClick: handleOpenFaq,
                  },
                ]}
              >
                <IconButton
                  size="2xl"
                  className="border-4 border-brand-yellow text-brand-yellow hover:bg-brand-yellow hover:text-neutral-light-pure"
                >
                  <span className="text-[32px] font-bold leading-[0.8]">?</span>
                </IconButton>
              </Menu>
            ),
          },
        ]}
      >
        <>
          <HeaderNavigation
            helpLink={VERDE_WHATSAPP_LINK}
            breadcrumb={[
              {
                label: "Plataforma Verde",
                action: () => navigate("/dashboard"),
              },
            ]}
            features={["help-action"]}
            menu={[
              {
                children: "Dashboard",
                icon: <DashboardIcon />,
                onClick: () => navigate("/dashboard"),
              },
              {
                children: "Operações",
                icon: <DocumentClipboardIcon />,
                onClick: () => navigate("/propostas"),
              },
              {
                children: "Produtores",
                icon: <FarmProducerIcon />,
                onClick: () => navigate("/clientes"),
              },
              ...(isRestricted
                ? []
                : [
                    {
                      children: "Simulador de crédito",
                      icon: <CalculatorIcon />,
                      onClick: () => navigate("/simulador"),
                    },
                  ]),
              {
                children: "Termos de Uso",
                onClick: () => window.open(VERDE_TERMS_OF_USAGE, "_blank"),
              },
              {
                children: "Políticas de privacidade",
                onClick: () => window.open(VERDE_PRIVACY_POLICY, "_blank"),
              },
            ]}
            actions={[
              {
                onClick: toggleNotificationsDrawer,
                children: (
                  <Ping
                    data-hide={unreadNotificationsCount === 0}
                    className="group"
                    classNames={{ anchor: "group-data-[hide=true]:hidden" }}
                  >
                    {notificationPopupAnchorPoint(<BellIcon />)}
                  </Ping>
                ),
              },
            ]}
            account={{
              avatar: user?.image?.file_url,
              name: user?.name,
              menu: [
                {
                  children: "Dados pessoais",
                  icon: <UserIcon />,
                  onClick: () => navigate("/minha-conta"),
                },
                {
                  children: "Minha empresa",
                  icon: <BuildingIcon />,
                  onClick: () => navigate("/minha-conta?tab=business"),
                },
                {
                  children: "Dados bancários",
                  icon: <CardBankIcon />,
                  onClick: () => navigate("/minha-conta?tab=bank-account"),
                },
                {
                  children: "Trocar senha",
                  icon: <KeyIcon />,
                  onClick: () => navigate("/minha-conta?tab=password"),
                },
              ],
              footer: (
                <Button
                  kind="secondary"
                  size="lg"
                  icon={<LogoutIcon />}
                  onClick={logout}
                >
                  Encerrar sessão
                </Button>
              ),
            }}
            addon={<Missions.StatusMonitor />}
          />

          <div className="container min-h-[86vh] py-8">
            <S.Container>
              <Outlet />
            </S.Container>
          </div>

          <S.Footer />

          <GuideDialog />

          {faq.open && <FaqDialog onClose={handleCloseFaq} home={faq.home} />}
        </>
      </FloatingProvider>
    </LayoutDefaultContext.Provider>
  );
}
