import { AlertDialog } from "@verde/compose-ui";
import { AuthProvider } from "@verde/modules";
import { ThemeProvider } from "@verde/ui";
import {
  AlertProvider,
  QueryProvider,
  httpClient,
  monitoring,
  translation,
} from "@verde/utils";
import MissionsProvider from "modules/missions/context";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";

import type { Advisor } from "@verde/entities";
import App from "./App";

import "./styles.css";

httpClient.init({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/advisor/`,
});

translation.init({
  lng: "pt",
});

monitoring.init({
  highlightId: process.env.REACT_APP_HIGHLIGHT_ID,
  enabled: process.env.REACT_APP_ENVIRONMENT === "production",
});

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID || "",
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <AlertProvider dialog={(alert) => <AlertDialog alert={alert} />}>
        <BrowserRouter>
          <QueryProvider>
            <AuthProvider
              context="advisor"
              baseUrl={process.env.REACT_APP_API_URL || ""}
            >
              <MissionsProvider>
                <App />
              </MissionsProvider>
            </AuthProvider>
          </QueryProvider>
        </BrowserRouter>
      </AlertProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
