import { Tag } from "@verde/ui-core";
import { memo, useMemo } from "react";
import { tv } from "tailwind-variants";

import { ProgressBar } from ".";
import { useMissionsContext } from "../context";

interface MissionStatusMonitorTriggerProps {
  onClick: () => void;
}

const styles = tv({
  slots: {
    base: "w-[480px] h-12 bg-neutral-light-1 border border-neutral-light-2 rounded overflow-hidden flex flex-col cursor-pointer hover:shadow-default duration-default",
    content: "h-full flex items-center px-4 justify-between gap-2",
  },
});

function MissionStatusMonitorTrigger({
  onClick,
}: MissionStatusMonitorTriggerProps) {
  const { activeMission, missionProgressBarKind, isAllMissionsCompleted } =
    useMissionsContext();

  const currentMissionTitle = useMemo(() => {
    if (!activeMission) return "";

    return (
      [
        "Qualificação de produtores",
        "Enviar proposta",
        "Confirmação e contratação",
        "Enquadramento e formalização",
      ][activeMission.step - 1] || ""
    );
  }, [activeMission]);

  const tagLabel = useMemo(() => {
    if (activeMission.status === "TIMEOUT") return "Prazo encerrado";
    if (activeMission.status === "INCOMPLETE") return "Incompleta";

    switch (activeMission.step) {
      case 3: {
        return activeMission.status !== "SUCCESS" ? "Em andamento" : "Completa";
      }
      case 4:
        return activeMission.status !== "SUCCESS" ? "Em andamento" : "Completa";
      default:
        return activeMission.status !== "SUCCESS"
          ? activeMission.deadline_days
          : "Completa";
    }
  }, [activeMission]);

  if (!activeMission.id || isAllMissionsCompleted || activeMission.step === 5)
    return null;

  return (
    <div className={styles().base()} onClick={onClick}>
      <div className={styles().content()}>
        <p className="flex items-center gap-1 text-base font-normal">
          {activeMission.step}ª Missão:{" "}
          <span className="text-lg font-semibold">{currentMissionTitle}</span>
        </p>

        <Tag
          size="sm"
          kind={
            activeMission.status === "SUCCESS"
              ? "success"
              : missionProgressBarKind === "danger"
                ? "error"
                : "highlight"
          }
          label={tagLabel}
        />
      </div>

      {!["SUCCESS", "INCOMPLETE", "TIMEOUT"].includes(activeMission.status) && (
        <ProgressBar
          size="small"
          classNames={{
            base: "mt-auto",
            progress: "rounded-none overflow-hidden",
          }}
        />
      )}
    </div>
  );
}

export default memo(MissionStatusMonitorTrigger);
