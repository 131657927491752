import React, { lazy } from "react";

import { PATH_APP } from "routes/paths";
import { LayoutDefault } from "layouts/Default";
import { Loadable } from "utils/routes";
import AuthProtect from "components/Auth/AuthProtect";

const Account = Loadable(lazy(() => import("./pages/Account")));

const clientsRoutes = {
  path: PATH_APP.account.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.account.root,
      element: <Account />,
    },
  ],
};

export default clientsRoutes;
