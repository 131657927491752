import {
  type Advisor,
  type Mission,
  type RatingV2,
  useCreateMission,
  useFetchActiveMission,
  useFetchMissions,
  useUpdateMission,
} from "@verde/entities";
import { useAuthContext } from "@verde/modules";
import { dayjs } from "@verde/utils";
import {
  createContext,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import {
  matchPath,
  matchRoutes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { PATH_APP } from "routes/paths";
import { Loadable } from "utils/routes";
import {
  ProposalApprovedDialog,
  ProposalContractDialog,
  ProposalDisbursedDialog,
} from "../components";
import type {
  MissionReducerAction,
  MissionReducerState,
  MissionReducerStatus,
  MissionStep,
  MissionsProviderProps,
  MissionsStateContext,
} from "./types";

const MissionsContext = createContext<
  MissionsStateContext<MissionReducerStatus, MissionStep> | undefined
>(undefined);

const ProducerDialog = Loadable(
  lazy(() => import("../components/producer-dialog")),
);

const ProposalDialog = Loadable(
  lazy(() => import("../components/proposal-dialog")),
);

export function useMissionsContext<
  StatusType = MissionReducerStatus,
  Step = MissionStep,
>() {
  const context = useContext(MissionsContext);
  if (!context) {
    throw new Error("useMissionsContext must be used within MissionsProvider.");
  }

  return context as unknown as MissionsStateContext<StatusType, Step>;
}

export default function MissionsProvider({ children }: MissionsProviderProps) {
  const { user } = useAuthContext<Advisor>();
  const activeMission = useFetchActiveMission(user?.id, {
    refetchOnWindowFocus: false,
  });
  const startMission = useCreateMission(user?.id);
  const missions = useFetchMissions(user?.id, { refetchOnWindowFocus: false });
  const updateMission = useUpdateMission(user?.id);

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * O reducer dialog é responsável por controlar o estado do dialog da missão
   *
   * @param {MissionReducerAction} action - Ação a ser realizada
   *
   * Exemplo de uso: dispatch({ open: "producer", status: "INCOMPLETE" })
   */
  const [missionDialog, dispatchMissionDialog] = useReducer(
    (
      _: MissionReducerState,
      action: MissionReducerAction,
    ): MissionReducerState => {
      const missionsStatuses = missions.data?.reduce(
        ({ ...lastMission }, { step, status }) => ({
          ...lastMission,
          [step]: status,
        }),
        {},
      );

      if (!action.open)
        return {
          open: false,
          status: "IDLE" as const,
          missions: missionsStatuses,
        };

      return {
        ...action,
        status:
          action?.status || activeMission.data?.status || ("IDLE" as const),
        missions: missionsStatuses,
      };
    },
    {
      open: false,
      status: "IDLE",
    },
  );

  /**
   * Define o reat_at de uma missão.
   */
  const markMissionAsRead = useCallback(
    async (id?: Mission["id"]) => {
      if (!id) return;

      await updateMission.mutateAsync({
        id,
        read_at: new Date(),
      });
    },
    [updateMission],
  );

  /**
   * Fecha o dialog de missão
   */
  const closeMissionDialog = useCallback(async () => {
    if (missionDialog.status === "SUCCESS" || activeMission.data?.step === 4) {
      await markMissionAsRead(missionDialog.id);
    }

    dispatchMissionDialog({ open: false, missions: missions.data });
  }, [missionDialog, markMissionAsRead, missions.data, activeMission]);

  /**
   * Quando a variável isRestricted é {true} signifca que o usuário não completou
   * as missões de acordo com as regras e está restringido de acessar alguns recursos
   * da plataforma.
   *
   * @returns {boolean}
   */
  const isRestricted = useMemo(
    () =>
      !!(
        activeMission.data &&
        ["INCOMPLETE", "TIMEOUT"].includes(activeMission.data?.status)
      ),
    [activeMission.data],
  );

  /**
   * A variável isAtDashboard é usada para verificar se o usuário está na dashboard
   *
   * @returns {boolean}
   */
  const isAtDashboard = useMemo(
    () => !!matchPath(location.pathname, PATH_APP.general.dashboard),
    [location.pathname],
  );

  /**
   * A variável isAllMissionsCompleted é usada para verificar se o usuário completou
   * todas as missões
   *
   * @returns {boolean}
   */
  const isAllMissionsCompleted = useMemo(
    () =>
      (missions.data?.every((mission) => mission.status === "SUCCESS") &&
        missions.data?.length === 4) ||
      user?.qualified ||
      false,
    [missions.data, user?.qualified],
  );

  /**
   * Busca dentre as missões do assessor se alguma delas já foi finalizada porém
   * o modal mostrando o sucesso ainda não foi exibido ao usuário.
   *
   * Ao encontrar uma missão nessas condições, o callback é chamado passando o
   * id da missão como parâmetro afins de exibir o dialog.
   *
   * @param {Mission["id"]} step - Número da etapa que deseja filtrar
   * @param {(missionId: Mission["id"]) => void} callback - Callback chamado ao encontrar uma missão
   */
  const shouldRenderMissionDoneDialog = useCallback(
    (
      step: Mission["step"],
      callback: (missionId: Mission["id"]) => void,
    ): void => {
      const mission = missions.data?.find((mission) => mission.step === step);

      if (mission?.finished && !mission.read_at) callback(mission.id);
    },
    [missions.data],
  );

  const someUnreadMission = useMemo(
    () =>
      missions.data?.find((mission) => mission.finished && !mission.read_at) ||
      false,
    [missions.data],
  );

  useEffect(() => {
    if (
      activeMission.data?.step === 5 &&
      activeMission.data?.status === "SUCCESS" &&
      !activeMission.data.read_at
    ) {
      return dispatchMissionDialog({
        open: "disbursed",
        id: activeMission.data.id,
      });
    }
  }, [activeMission.data]);

  /**
   * Detecta mudanças na rota para realizar o trigger do dialog da missão
   * de forma automática e centralizada
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      missionDialog.open ||
      missionDialog.status !== activeMission.data?.status
    ) {
      return;
    }

    if (
      !!matchPath(location.pathname, PATH_APP.workflowClient.search) &&
      (location.state?.trigger_mission_dialog ||
        isRestricted ||
        (activeMission.data?.step === 1 &&
          activeMission.data.status === "SUCCESS"))
    ) {
      return dispatchMissionDialog({
        open: "producer",
      });
    }

    if (
      !!matchPath(location.pathname, PATH_APP.workflowProposal.root) &&
      activeMission.data?.step === 2 &&
      (location.state?.trigger_mission_dialog || isRestricted)
    ) {
      return dispatchMissionDialog({
        open: "proposal",
      });
    }

    if (
      !!matchPath(location.pathname, PATH_APP.general.dashboard) &&
      activeMission.data?.step === 3 &&
      activeMission.data?.proposal_attempts === 0 &&
      location.state?.trigger_mission_dialog
    ) {
      return dispatchMissionDialog({
        open: "approved",
      });
    }

    if (
      !!matchRoutes(
        [{ path: PATH_APP.workflowContract.resume }],
        location.pathname,
      ) &&
      activeMission.data?.step === 3
    ) {
      activeMission.refetch();

      return;
    }

    if (activeMission.data?.step === 4 && !activeMission.data?.read_at) {
      return dispatchMissionDialog({
        id: activeMission.data.id,
        open: "contract",
      });
    }

    dispatchMissionDialog({ open: false, missions: missions.data });
  }, [
    someUnreadMission,
    shouldRenderMissionDoneDialog,
    location,
    isRestricted,
    activeMission.data,
    activeMission.refetch,
    missions.data,
    missionDialog.status,
  ]);

  /**
   * Controla o modal de status utilizado no Header da aplicação
   *
   * @param {boolean} [open] - Define se o monitor deve abrir ou fechar, se não for passado, a ação é reversa ao estado atual do monitor, ou seja, se estiver aberto, irá fechar e vice-versa.
   * @returns {void}
   */
  const toggleMissionStatusMonitor = useCallback(
    (open?: boolean) => {
      const shouldBeOpen = open && !missionDialog.open;

      dispatchMissionDialog({
        open: shouldBeOpen ? "monitor" : false,
      });
    },
    [missionDialog.open],
  );

  /**
   * A variável shouldDisplayGuide é usada para exibir o guia de boas-vindas
   * quando o usuário é novo, está na dashboard e não possui missão ativa o guia
   * é exibido.
   *
   * @returns {boolean}
   */
  const shouldDisplayGuide = useMemo(() => {
    return (
      !user?.qualified &&
      isAtDashboard &&
      activeMission?.isFetched &&
      activeMission?.data?.id === undefined
    );
  }, [user, isAtDashboard, activeMission.isFetched, activeMission.data]);

  /**
   * Função responsável por fazer o redirect automático para a rota da
   * próxima missão
   *
   * @returns {void}
   */

  const redirectToMissionPathByStep = useCallback(
    async (step: Mission["step"]) => {
      if (!step) return;

      const routesByStep = [
        PATH_APP.workflowClient.search,
        PATH_APP.workflowProposal.root,
        PATH_APP.general.dashboard,
        PATH_APP.propostas.details,
      ];

      const activeMissionRoute = routesByStep[step - 1];

      navigate(activeMissionRoute, {
        state: {
          trigger_mission_dialog: true,
        },
      });
    },
    [navigate],
  );

  /**
   * Inicia a primeira missão caso não há missão ativa ou avança para a próxima missão
   *
   * Sempre que uma missão é iniciada ou avançada, a missão atual é atualizada.
   * A função onActiveStepChange é chamada para realizar o redirect automático.
   *
   * @param {boolean} [force] - Ignora se a missão ativa está ou não finalizada
   * @returns {Promise<void>}
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const startOrAdvanceMission = useCallback(
    async (shouldForce?: boolean) => {
      if (
        activeMission.data?.id &&
        (!activeMission.data?.finished || shouldForce)
      ) {
        redirectToMissionPathByStep(activeMission.data?.step);
        await closeMissionDialog();

        return Promise.resolve();
      }

      return await new Promise((resolve) =>
        resolve(
          startMission.mutateAsync().then(async (value) => {
            redirectToMissionPathByStep(value.step);

            await closeMissionDialog();
          }),
        ),
      );
    },
    [
      activeMission,
      startMission,
      redirectToMissionPathByStep,
      missionDialog?.id,
    ],
  );

  /**
   * A função abaixo é chamada sempre que o usuário finaliza uma tentativa
   * da primeira missão, que é cadastrar um produtor qualificado.
   *
   * Ela é responsável por realizar o refetch do active mission e também definir
   * com qual status deve ser aberto o dialog da missão.
   */

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const onFirstMissionAttemptComplete = useCallback(
    (situation: RatingV2["situation"]) => {
      if (activeMission.data?.step !== 1) return;

      activeMission.refetch().then((activeMission) => {
        const isSuccess =
          activeMission.data?.status === "SUCCESS" || situation === "QUALIFIED";

        dispatchMissionDialog({
          open: "producer",
          status: isSuccess
            ? "SUCCESS"
            : activeMission.data?.status === "INCOMPLETE"
              ? "INCOMPLETE"
              : situation,
        });
      });
    },
    [missions],
  );

  /**
   * A função abaixo é chamada sempre que o usuário finaliza uma tentativa
   * da segunda missão, que é criar uma proposta.
   */

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const onSecondMissionAttemptComplete = useCallback(() => {
    if (
      (activeMission.data?.proposal_attempts || 0) >=
        (activeMission.data?.proposal_limit || 5) ||
      user?.qualified
    )
      return;

    missions.refetch().then(async (missions) => {
      const secondMission = missions.data?.find(
        (mission) => mission.step === 2,
      );
      await markMissionAsRead(secondMission?.id);
      await activeMission.refetch();

      dispatchMissionDialog({
        open: "proposal",
        status: secondMission?.status,
      });
    });
  }, [activeMission.data?.status]);

  /**
   * A função abaixo é chamada sempre que o usuário finaliza uma tentativa
   * da terceira missão, que é finalizar a etapa de cadastro da proposta.
   */

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const onThirdMissionAttemptComplete = useCallback(() => {
    activeMission.refetch().then((activeMission) => {
      if (activeMission.data?.step === 4 && !activeMission.data.finished) {
        dispatchMissionDialog({
          open: "contract",
        });
      }
    });
  }, [activeMission.data]);

  /**
   * A função abaixo é chamada sempre que o usuário tenta enviar uma proposta
   * para validação.
   */
  const canSentProposalToValidation = useCallback(() => {
    if (user?.qualified) return true;

    if (
      (activeMission.data?.proposal_attempts || 0) >=
      (activeMission.data?.proposal_limit || 0)
    ) {
      dispatchMissionDialog({
        open: "proposal",
        status: "INCOMPLETE",
      });

      return false;
    }

    return true;
  }, [user, activeMission.data]);

  /**
   * A função abaixo é chamada sempre que o usuário é redirecionado para a
   * rota de resumo do workflow de contrato
   */
  const onSentProposalToFormalization = useCallback(() => {
    if (activeMission.data?.step !== 4) return;

    if (!activeMission.data?.read_at) {
      return dispatchMissionDialog({
        open: "contract",
      });
    }
  }, [activeMission.data]);

  /**
   * A função abaixo retorna uma porcentagem baseada na data de criação e
   * no prazo da missão atual.
   */
  const missionDailyProgress = useMemo(() => {
    if (!activeMission.data?.id) return 0;

    const stripTime = (date: Date) => dayjs(date).startOf("day");

    const createdAt = stripTime(activeMission.data.created_at);
    const deadlineAt = stripTime(activeMission.data.deadline_at);
    const today = stripTime(new Date());

    const totalDays = deadlineAt.diff(createdAt, "day");
    const elapsedDays = today.diff(createdAt, "day");
    const clampedElapsedDays = Math.max(1, Math.min(elapsedDays, totalDays));

    let percentage = (clampedElapsedDays / totalDays) * 100;

    if (today.isSame(deadlineAt, "day")) {
      percentage = 99;
    } else if (today.isAfter(deadlineAt)) {
      percentage = 100;
    }

    return percentage;
  }, [activeMission.data]);

  /**
   * A função abaixo retorna o kind baseado na porcentagem de progresso da missão
   */
  const missionProgressBarKind = useMemo(() => {
    if (
      missionDailyProgress >= 70 ||
      activeMission.data?.status === "INCOMPLETE"
    )
      return "danger" as const;
    return "warning" as const;
  }, [activeMission.data, missionDailyProgress]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const contextValue = useMemo(() => {
    return {
      isAllMissionsCompleted,
      missionDailyProgress,
      missionProgressBarKind,
      missionDialog,
      closeMissionDialog,
      dispatchMissionDialog,
      activeMission:
        (activeMission.data as Mission<MissionStep>) || ({} as Mission),
      shouldDisplayGuide,
      startOrAdvanceMission,
      isStartingOrAdvancingMission: startMission.isLoading,
      isRestricted,
      onFirstMissionAttemptComplete,
      onSecondMissionAttemptComplete,
      onThirdMissionAttemptComplete,
      onSentProposalToFormalization,
      toggleMissionStatusMonitor,
      canSentProposalToValidation,
      markMissionAsRead,
      missions,
    };
  }, [
    shouldDisplayGuide,
    startOrAdvanceMission,
    startMission.isLoading,
    missions,
  ]);

  return (
    <MissionsContext.Provider value={contextValue}>
      {children}

      {missionDialog.open === "producer" && <ProducerDialog open />}

      {missionDialog.open === "proposal" && <ProposalDialog open />}

      {(someUnreadMission || activeMission?.data?.step === 3) &&
        missionDialog.open === "approved" && <ProposalApprovedDialog open />}

      {(someUnreadMission || activeMission?.data?.step === 4) &&
        missionDialog.open === "contract" && <ProposalContractDialog open />}

      {activeMission?.data?.step === 5 &&
        missionDialog.open === "disbursed" && <ProposalDisbursedDialog open />}
    </MissionsContext.Provider>
  );
}
