import { Navigate } from "react-router-dom";
import { useRoutes } from "react-router-dom";

import authRoutes from "modules/auth/auth.routes";
import onboardingRoutes from "modules/onboarding/onboarding.routes";
import dashboardRoutes from "modules/dashboard/dashboard.routes";
import clientsRoutes from "modules/clients/clients.routes";
import proposalsRoutes from "modules/proposals/proposals.routes";
import accountRoutes from "modules/account/account.routes";
import simulatorRoutes from "modules/simulator/simulator.routes";
import workflowClientRoutes from "modules/workflow-client/workflow-client.routes";
import workflowProposalRoutes from "modules/workflow-proposal/workflow-proposal.routes";
import workflowContractRoutes from "modules/workflow-contract/workflow-contract.routes";
import NotFound from "pages/NotFound";
import { useMissionsContext } from "modules/missions/context";

export default function Router() {
  const { isRestricted } = useMissionsContext();

  return useRoutes([
    authRoutes,
    onboardingRoutes,
    dashboardRoutes,
    accountRoutes,
    !isRestricted && simulatorRoutes,
    clientsRoutes,
    proposalsRoutes,
    workflowProposalRoutes,
    workflowContractRoutes,
    workflowClientRoutes,
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
