import GuestProtect from "components/Auth/GuestProtect";
import { lazy } from "react";
import { PATH_PAGE } from "routes/paths";
import { Loadable } from "utils/routes";
import { CleanLayout } from "layouts/Clean";

const Login = Loadable(lazy(() => import("./pages/Login")));
const ResetPassword = Loadable(lazy(() => import("./pages/ResetPassword")));
const ChangePassword = Loadable(lazy(() => import("./pages/ChangePassword")));
const Introduction = Loadable(lazy(() => import("./pages/Introduction")));

const authRoutes = {
  path: PATH_PAGE.auth.root,
  element: <CleanLayout />,
  children: [
    {
      path: PATH_PAGE.auth.login,
      element: (
        <GuestProtect>
          <Login />
        </GuestProtect>
      ),
    },
    {
      path: PATH_PAGE.auth.resetPassword,
      element: <ResetPassword />,
    },
    {
      path: PATH_PAGE.auth.changePassword,
      element: <ChangePassword />,
    },
    {
      path: PATH_PAGE.auth.firstAccess,
      element: <ChangePassword isFirstAccess />,
    },
    {
      path: PATH_PAGE.auth.introduction,
      element: <Introduction />,
    },
  ],
};

export default authRoutes;
