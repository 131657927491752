import React, { lazy } from "react";

import { Loadable } from "utils/routes";
import { PATH_APP } from "routes/paths";
import { CleanLayout } from "layouts/Clean";
import AuthProtect from "components/Auth/AuthProtect";

const Appointment = Loadable(lazy(() => import("./pages/appointment")));

const workflowRoutes = {
  element: (
    <AuthProtect>
      <CleanLayout />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.onboarding.appointment,
      element: <Appointment />,
    },
  ],
};

export default workflowRoutes;
